"use client";

import TextComponent from "@common/typography/TextComponent";
import GravityLogo from "@components/icons/GravityLogo";
import React, { PropsWithChildren } from "react";
import { Row } from "react-bootstrap";
import { StyledFooter } from "src/app/error";
import { DefaultTheme, useTheme } from "styled-components";
import Image, { StaticImageData } from 'next/image';
import Ripple from './ripple.gif'

interface LoadingComponentProps {
    loadingText?: string;
    loaderImg?: StaticImageData;
}

const LoadingComponent = ({
    loadingText = "MERCHANT PROCESSING APPLICATION LOADING...",
    loaderImg = Ripple,
}: PropsWithChildren<LoadingComponentProps>) => {
    const theme: DefaultTheme & { primary?: string } = useTheme() || {};

    return (
        <Row className="d-flex flex-column position-fixed top-50 start-50 translate-middle align-items-center w-100 h-100 justify-content-center loader">
            <div className="my-auto text-center">
                <GravityLogo fill={theme?.primary} />

                <TextComponent fontWeight="600" className="my-4 text-center">
                    {loadingText}
                </TextComponent>

                <Image src={loaderImg} width="200" unoptimized height="200" alt="ripple" />
            </div>
            <StyledFooter className="mt-auto" />
        </Row>
    );
};

export default LoadingComponent;
